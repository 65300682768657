import React from 'react';
import { StyledNavBar, StyledLogo, StyledNavLinks, StyledLogoImage } from './StyledComponents';

const Header = () => (
  <StyledNavBar>
    <div style={{ display: 'flex', alignItems: 'center' }}>
    <StyledLogoImage src="/dcf-logo-transparent.png" alt="FLDCF Logo" />
      <StyledLogo>SAMH Licensing Registration</StyledLogo>
    </div>
    <StyledNavLinks>
      {/* Add any other navigation links here if needed */}
    </StyledNavLinks>
  </StyledNavBar>
);

export default Header;
