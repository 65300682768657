const theme = {
  colors: {
    primary: '#07579D',
    secondary: '#6C757D',
    success: '#28a745',
    successLight: '#d4edda', // Light green background for success messages
    successDark: '#155724',  // Dark green text for success messages
    danger: '#dc3545',
    dangerLight: '#F8D7DA', // Light red background for form errors
    dangerDark: '#721C24',  // Dark red text for form errors
    warning: '#ffc107',
    info: '#17a2b8',
    light: '#f8f9fa',
    dark: '#343a40',
    white: '#ffffff',
    black: '#000000',
  },
  fonts: {
    main: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  fontSizes: {
    xxs: '0.5rem',   // Extra Extra Small
    xs: '0.75rem',   // Extra Small
    sm: '0.875rem',  // Small
    md: '1rem',      // Medium (Base)
    lg: '1.125rem',  // Large
    xl: '1.25rem',   // Extra Large
    xxl: '1.5rem',   // Extra Extra Large
    h2: '2rem',    // Triple Extra Large
    h1: '2.25rem', // Quadruple Extra Large
  },
  fontWeights: {
    regular: 400,
    medium: 500,   // 'Medium' or 'Semi-Bold'
    bold: 700,
  },
};

export default theme;
