import React, { useState } from 'react';
import {
  Form,
  FormGroup,
  FloatingLabel,
  Input,
  Button,
  FormErrorMessage,
  FormSuccessMessage,
  InputErrorMessage,
  FormContainer
} from 'components/common';
import { registerUser } from 'services/api'; // Make sure to import the `registerUser` function from your services/api

const RegistrationForm = () => {
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', confirmEmail: '' });
  const [errors, setErrors] = useState({});
  const [focusedFields, setFocusedFields] = useState({});
  const [loading, setLoading] = useState(false); // Add state for loading
  const [message, setMessage] = useState({ text: '', error: false }); // Add state for message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    setFocusedFields({ ...focusedFields, [name]: true });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setFocusedFields({ ...focusedFields, [name]: false });
  };

  const validateEmail = (email) => {
    // Basic email regex pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'Required field!';
    if (!formData.lastName) newErrors.lastName = 'Required field!';
    if (!formData.email) {
      newErrors.email = 'Required field!';
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email address!';
    }
    if (formData.email !== formData.confirmEmail) newErrors.confirmEmail = 'Emails do not match!';
    if (!formData.confirmEmail) newErrors.confirmEmail = 'Required field!';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const { confirmEmail, ...formDataToSend } = formData;

    setLoading(true);
    setMessage({ text: null, error: false });

    try {
      const response = await registerUser(formDataToSend);

      let responseData = {};
      try {
        responseData = await response.json();
      } catch (parseError) {
        // Handle cases where response is not JSON
        responseData = { detail: 'An unexpected error occurred.' };
      }

      if (!response.ok) {
        const errorMessage = responseData.detail || 'Registration failed!';
        setMessage({ text: errorMessage, error: true });
      } else {
        const successMessage = responseData.message || 'Registration successful!';
        setMessage({ text: successMessage, error: false });
      }
    } catch (error) {
      setMessage({ text: 'Registration failed!', error: true });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>
          <Input
            type="text"
            name="firstName"
            placeholder=" "
            value={formData.firstName}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={loading}
            isInvalid={!!errors.firstName}
            hasContent={!!formData.firstName}
          />
          <FloatingLabel
            htmlFor="firstName"
            isInvalid={!!errors.firstName}
            isFocused={focusedFields.firstName}
          >
            First Name
          </FloatingLabel>
          <InputErrorMessage>{errors.firstName}</InputErrorMessage>
        </FormGroup>

        <FormGroup>
          <Input
            type="text"
            name="lastName"
            placeholder=" "
            value={formData.lastName}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={loading}
            isInvalid={!!errors.lastName}
            hasContent={!!formData.lastName}
          />
          <FloatingLabel
            htmlFor="lastName"
            isInvalid={!!errors.lastName}
            isFocused={focusedFields.lastName}
          >
            Last Name
          </FloatingLabel>
          <InputErrorMessage>{errors.lastName}</InputErrorMessage>
        </FormGroup>

        <FormGroup>
          <Input
            type="email"
            name="email"
            placeholder=" "
            value={formData.email}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={loading}
            isInvalid={!!errors.email}
            hasContent={!!formData.email}
          />
          <FloatingLabel
            htmlFor="email"
            isInvalid={!!errors.email}
            isFocused={focusedFields.email}
          >
            Email
          </FloatingLabel>
          <InputErrorMessage error>{errors.email}</InputErrorMessage>
        </FormGroup>

        <FormGroup>
          <Input
            type="email"
            name="confirmEmail"
            placeholder=" "
            value={formData.confirmEmail}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={loading}
            isInvalid={!!errors.confirmEmail}
            hasContent={!!formData.confirmEmail}
          />
          <FloatingLabel
            htmlFor="confirmEmail"
            isInvalid={!!errors.confirmEmail}
            isFocused={focusedFields.confirmEmail}
          >
            Confirm Email
          </FloatingLabel>
          <InputErrorMessage error>{errors.confirmEmail}</InputErrorMessage>
        </FormGroup>

        <Button type="submit" loading={loading}>
          Submit
        </Button>
        {message.text && message.error && (
          <FormErrorMessage>{message.text}</FormErrorMessage>
        )}
        {message.text && !message.error && (
          <FormSuccessMessage>{message.text}</FormSuccessMessage>
        )}
      </Form>
    </FormContainer>
  );
};

export default RegistrationForm;
