import React from 'react';
import { StyledInputErrorMessage } from './StyledComponents';

const InputErrorMessage = ({ children }) => {
    return (
        <StyledInputErrorMessage>
            {children}
        </StyledInputErrorMessage>
    );
};

export default InputErrorMessage;
