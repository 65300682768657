import React from 'react';
import { Container, H1 } from 'components/common';
import RegistrationForm from 'components/RegistrationForm';

const RegistrationPage = () => (
  <Container>
    <H1>Register</H1>
    <RegistrationForm />
  </Container>
);

export default RegistrationPage;
