import React from 'react';
import { StyledButton } from './StyledComponents';
import { FaSpinner } from 'react-icons/fa';

const Button = ({ children, loading, disabled, ...props }) => {
  return (
    <StyledButton disabled={loading || disabled} {...props}>
      {loading ? (
        <>
          <FaSpinner className="spinner" />
          <span>{children}</span>
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Button;
