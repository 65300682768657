// src/components/common/ErrorIcon.jsx
import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import styled from 'styled-components';

const StyledErrorIcon = styled(FaExclamationCircle)`
  color: ${(props) => props.theme.colors.danger};
  margin-right: 0.5rem;
  vertical-align: middle;
`;

const ErrorIcon = () => {
  return <StyledErrorIcon size={20} />;
};

export default ErrorIcon;
