import React from 'react';
import { StyledInput } from './StyledComponents';

const Input = ({ hasContent, isInvalid, ...props }) => {
  return (
    <StyledInput
      className={hasContent ? 'has-content' : ''}
      $isInvalid={isInvalid}
      {...props}
    />
  );
};

export default Input;
