import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import GlobalStyles from 'styles/globalStyles';
import RegistrationPage from 'pages/RegistrationPage';
import { Header, Footer } from 'components/layout';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import { LayoutContainer, MainContent } from 'components/layout';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <LayoutContainer>
          <Header />
          <MainContent>
            <Routes>
              <Route path="/" element={<Navigate to="/register" />} />
              <Route path="/register" element={<RegistrationPage />} />
            </Routes>
          </MainContent>
          <Footer />
        </LayoutContainer>
      </Router>
    </ThemeProvider>
  );
};

export default App;
