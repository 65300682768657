const API_URL = process.env.REACT_APP_API_URL;

export const registerUser = async (userData) => {
  const response = await fetch(`${API_URL}/user/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });
  return response;
};
