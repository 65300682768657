import styled from 'styled-components';

export const StyledLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledMainContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledNavBar = styled.nav`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    height: 50px;
    margin-right: 10px;
  }
`;

export const StyledLogo = styled.h1`
  color: white;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const StyledLogoImage = styled.img`
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.light};
`;

export const StyledNavLinks = styled.div`
  a {
    color: white;
    margin-left: 2rem;
    font-size: 1.2rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledFooterContainer = styled.footer`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 1rem;
  text-align: center;
`;
