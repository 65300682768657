import React from 'react';
import { StyledFooterContainer } from './StyledComponents';

const Footer = () => (
  <StyledFooterContainer>
    &copy; {(new Date().getFullYear())} SAMH Licensing Registration. All rights reserved.
  </StyledFooterContainer>
);

export default Footer;
