import styled from 'styled-components';
import { FaExclamationCircle } from 'react-icons/fa';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.light};
`;

export const StyledH1 = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 2rem;
  font-size: ${(props) => props.theme.fontSizes.h1};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledFormGroup = styled.div`
  position: relative;
  margin-bottom: 0.75rem;
`;

export const StyledFloatingLabel = styled.label`
  position: absolute;
  top: 0.5rem; /* Matches input's padding-top */
  transform: none;
  left: 0.5rem;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0 0.25rem;
  color: ${(props) =>
    props.$isInvalid ? props.theme.colors.danger : props.theme.colors.secondary};
  font-size: ${(props) => props.theme.fontSizes.md};
  transition: all 0.15s ease-in-out;
  pointer-events: none;
  z-index: 1; /* Ensure the label appears above the input */
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid
    ${(props) =>
    props.$isInvalid ? props.theme.colors.danger : props.theme.colors.secondary};
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSizes.md};

  &:focus {
    outline: none;
    border-color: ${(props) =>
    props.$isInvalid ? props.theme.colors.danger : props.theme.colors.primary};
  }

  &:focus + ${StyledFloatingLabel},
  &.has-content + ${StyledFloatingLabel} {
    top: -0.75rem;
    left: 0.5rem;
    font-size: ${(props) => props.theme.fontSizes.xs};
    color: ${(props) =>
    props.$isInvalid ? props.theme.colors.danger : props.theme.colors.primary};
  }
`;

export const StyledButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) =>
    !props.disabled && props.theme.colors.dark};
  }

  .spinner {
    margin-right: 0.5rem;
    animation: spin 1s linear infinite;
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSizes.lg};
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledInputErrorMessage = styled.p`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.danger};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  min-height: 1.25rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  text-align: left;
  visibility: ${(props) => (props.children ? 'visible' : 'hidden')};
`;


export const StyledFormErrorMessage = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.dangerLight};
  color: ${(props) => props.theme.colors.dangerDark};
  border: 1px solid ${(props) => props.theme.colors.danger};
  border-radius: 4px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  visibility: ${(props) => (props.children ? 'visible' : 'hidden')};
`;

export const StyledFormSuccessMessage = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.successLight};
  color: ${(props) => props.theme.colors.successDark};
  border: 1px solid ${(props) => props.theme.colors.success};
  border-radius: 4px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const StyledFormContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 480px;
  text-align: center;
`;

export const ErrorIcon = styled(FaExclamationCircle)`
  margin-right: 0.25rem;
  color: ${(props) => props.theme.colors.danger};
  font-size: 1em; /* Makes the icon the same size as the text */
  vertical-align: middle;
`;
